<template>
  <div>
    <div v-if="response && response.vehicleRegistration">
      <section class="section">
        <div class="container">
          <div class="notification" v-if="batch">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <router-link
                  class="has-text-link"
                  :to="{
                    name: 'autocheck-summary',
                    params: { uuid: batch.uuid }
                  }"
                  @click.native="$mxp.track('autocheck_page_view_batch_page')"
                >
                  <span>Back to Batch page</span>
                </router-link>
              </div>
              <div class="column is-narrow">
                <BatchDropdown
                  v-bind="{ vrm, batchChecks }"
                  @recheck="recheck('tabs')"
                  :loading="$wait.is('loading')"
                  v-if="checks"
                />
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <VehicleCarousel
                    v-bind="{ vrm, photos, status }"
                    @check="recheck('carousel')"
                  />
                  <div class="columns is-multiline is-marginless is-mobile">
                    <div class="column is-12">
                      <div class="columns is-mobile">
                        <div class="column is-6">
                          <span class="icon">
                            <i class="fas fa-tachometer" />
                          </span>
                          <span v-if="!mileageEstimated && !mileage">
                            Mileage
                          </span>
                          <span v-else-if="mileageEstimated">
                            Estimated mileage
                          </span>
                          <span v-else>Exact mileage</span>
                        </div>
                        <div class="column is-6 has-text-right is-narrow">
                          <span v-if="mileage">{{
                            formatNumber(mileage)
                          }}</span>
                          <span v-else class="has-text-grey">N/A</span>
                        </div>
                      </div>
                    </div>
                    <div class="column has-background-white-ter">
                      <span class="icon">
                        <i class="fas fa-id-card" />
                      </span>
                      <span>VIN</span>
                    </div>
                    <div class="column has-text-right has-background-white-ter">
                      <span v-if="vin">{{ vin }}</span>
                      <span class="icon" v-else>
                        <i class="fal fa-spinner fa-pulse" />
                      </span>
                    </div>
                    <div class="column is-12">
                      <div class="columns is-multiline is-mobile is-vcentered">
                        <div class="column">
                          <p class="title is-6">
                            <span>AutoCheck certificate</span>
                            <a
                              class="icon ml-0"
                              v-tippy="{ placement: 'right' }"
                              :title="capCertDisclaimer"
                            >
                              <i class="fas fa-info-circle has-text-warning">
                              </i>
                            </a>
                          </p>
                          <p class="subtitle is-7">
                            Print-friendly PDF certificates
                          </p>
                        </div>
                        <div class="column is-narrow has-text-right">
                          <button
                            class="button is-info is-rounded is-size-7-touch"
                            @click.prevent="downloadPdf"
                            :disabled="!isDataLoaded"
                            :class="{ 'is-loading': $wait.is('downloading') }"
                          >
                            <span>Generate now</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-8">
              <AutoCheckSummary />
            </div>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="container">
          <div class="columns is-multiline">
            <div class="column is-4">
              <CazanaInsight
                v-if="cazana && response.cazana"
                :data="response.cazana"
              />
              <VehicleSpec :preload="specs" />
            </div>
            <div class="column is-8">
              <div :class="{ 'pl-5': $mq !== 'mobile' }">
                <div class="columns is-multiline is-mobile is-vcentered">
                  <div class="column is-narrow">
                    <div class="columns is-vcentered">
                      <div class="column">
                        <AutoCheckPrevious
                          v-if="date"
                          v-bind="{ date, checks }"
                          @recheck="recheck('tabs')"
                          :loading="$wait.is('loading')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <AutoCheckTabs
                      :content="content"
                      @goTo="goTo"
                      data-test-id="autocheck-tabs"
                    />
                  </div>
                </div>
                <div v-if="$wait.is('companion')">
                  <Spinner />
                </div>
                <AutoCheckResponse
                  ref="response"
                  v-bind="{ data: response, date }"
                  v-else-if="
                    response &&
                    response.vehicleRegistration &&
                    content === 'autocheck'
                  "
                  data-test-id="autocheck-report"
                />
                <VehicleInfoTimeline
                  :load="timeline"
                  v-else-if="timeline && content === 'timeline'"
                  data-test-id="autocheck-timeline"
                />
                <div
                  v-else-if="additionalData && content === 'additional-data'"
                >
                  <div
                    v-for="(group, index) in additionalData"
                    :key="index"
                    class="content"
                  >
                    <AdditionalData
                      data-test-id="autocheck-data"
                      v-bind="{ group }"
                    />
                  </div>
                </div>
                <TableEmpty
                  v-else-if="
                    (!timeline && content === 'timeline') ||
                    (!additionalData && content === 'additional-data')
                  "
                  text="We couldn't surface any data for this vehicle"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else class="hero is-fullheight">
      <div class="hero-body">
        <div class="container has-text-centered">
          <button class="button is-text is-large is-loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../stores'
import registerModule from '@/store/services'
import { mapActions, mapGetters } from 'vuex'
import { formatNumber } from 'accounting'
import * as autocheck from '../services'
import { getPdfMapping, getFuelEconomyPdf } from '../services/pdfs'
import { getBatchPdfs } from '@/services/PdfService'
import * as companion from 'modules/companion/services'
export default {
  name: 'AutoCheck',
  components: {
    VehicleSpec: () => import('modules/vehicle-profile/components/VehicleSpec'),
    VehicleCarousel: () =>
      import('core/modules/vehicle-carousel/components/VehicleCarousel'),
    AutoCheckSummary: () => import('../components/AutoCheckSummary'),
    AutoCheckPrevious: () => import('../components/AutoCheckPrevious'),
    BatchDropdown: () =>
      import('modules/autocheck-batch/components/AutoCheckBatchDropdown'),
    CazanaInsight: () => import('../components/CazanaInsight'),
    AutoCheckResponse: () => import('../components/AutoCheckResponse'),
    AdditionalData: () => import('../components/AutoCheckAdditionalData'),
    VehicleInfoTimeline: () =>
      import('modules/vehicle-profile/components/VehicleInfoTimeline'),
    AutoCheckTabs: () => import('../components/AutoCheckTabs'),
    TableEmpty: () => import('core/components/TableEmpty'),
    Spinner: () => import('core/components/Spinner')
  },
  data: () => ({ content: 'autocheck' }),
  created() {
    registerModule('autocheck', store)
  },
  destroyed() {
    this.reset()
  },
  computed: {
    ...mapGetters('autocheck', [
      'response',
      'request',
      'previousChecks',
      'description',
      'grid',
      'vrm',
      'vin',
      'mileage',
      'mileageEstimated',
      'cazana',
      'date',
      'timeline',
      'photos',
      'fuelPrices',
      'hasFuelData',
      'specs',
      'batch',
      'additionalData',
      'capToken'
    ]),
    ...mapGetters('auth', ['isCompanionUser', 'user', 'franchises']),
    isDataLoaded() {
      return !!this.request
    },
    status() {
      return autocheck.getStatus(this.response)
    },
    checks() {
      const { uuid } = this.$route.params
      const checks = this.previousChecks
        ? this.previousChecks.filter((check) => check.uuid !== uuid)
        : []
      return checks
    },
    batchChecks() {
      return this.batch?.requests.filter(({ vrm }) => vrm !== this.vrm)
    },
    capCertDisclaimer() {
      return 'Due to provider restrictions, CAP valuation data will not be displayed within the certificate. Instead, you will receive an email containing the CAP valuation data when you request an AutoCheck certificate.'
    }
  },
  methods: {
    ...mapActions('autocheck', ['fetch', 'reset']),
    formatNumber,
    async goToCompanion() {
      const { isCompanionUser, vrm, mileage, date } = this
      if (!isCompanionUser) {
        this.$mxp.track('autocheck_tabs_companion_upsell')
        return this.$modal.open('companion/UpsellCompanion')
      } else if (!this.$wait.is('companion')) {
        this.$mxp.track('autocheck_tabs_companion_request')
        try {
          this.$wait.start('companion')
          await companion.value({ vrm, mileage, date })
        } catch (err) {
          this.$mxp.track('autocheck_tabs_companion_request_failed')
        } finally {
          this.$wait.end('companion')
        }
      }
    },
    async recheck(component) {
      this.$mxp.track(`autocheck_${component}_recheck`)
      this.$wait.start('loading')
      try {
        const data = await autocheck.precheck({
          value: this.vrm,
          type: 'vrm'
        })

        this.$modal.open('autocheck/AutoCheckOptions', {
          vrm: data.vrm,
          vin: data.vin,
          description: autocheck.buildPreDescription(data),
          mileage: data.mileage
        })
      } catch (err) {
        this.$mxp.track(`autocheck_${component}_precheck_failed`)
        const msg = err?.response?.data?.error?.message
        this.$notify(msg || 'Currently unable to perform a check')
      } finally {
        this.$wait.end('loading')
      }
    },
    goTo(tab) {
      if (tab === 'companion') {
        this.goToCompanion()
      } else {
        this.$mxp.track(`autocheck_tabs_${tab}_toggle`)
        this.content = tab
      }
    },
    downloadPdf() {
      const { franchises, hasFuelData } = this
      if (franchises?.length) {
        this.$modal.open('autocheck/AutoCheckCertificates', {
          franchises,
          downloadFuelEconomy: hasFuelData,
          downloadGenericCertifcate: this.generateAutoCheckPdf
        })

        this.$mxp.track('autocheck_certificates_open_modal')
      } else {
        this.generateAutoCheckPdf()
      }
    },
    async generateAutoCheckPdf() {
      this.$wait.start('downloading')
      this.$mxp.track('autocheck_download_full_PDF')

      try {
        const pdf = getPdfMapping({ request: this.request })
        const pdfs = [pdf]
        if (this.hasFuelData) pdfs.push(getFuelEconomyPdf())
        await this.$node.post('/autocheck-cap/request-email', {
          email: this.user.email,
          capToken: this.capToken
        })
        await getBatchPdfs(pdfs, this.request.vrm, 'file')
      } catch {
        this.$mxp.track('autocheck_download_full_PDF_failed')
        this.$notify('Failed to export PDF')
      } finally {
        this.$wait.end('downloading')
      }
    }
  },
  watch: {
    async '$route.params'({ uuid }) {
      await this.fetch(uuid)
    }
  },
  async mounted() {
    const { uuid } = this.$route.params
    try {
      await this.fetch(uuid)
    } catch (err) {
      this.$notify('AutoCheck unavailable')
    }
  }
}
</script>
